<template>
  <v-container fluid>
    <v-row justify="center">
      <v-col cols="10" sm="8" md="6" lg="4">
        <v-card>
          <v-toolbar :color="actionColor" dark>
            <v-toolbar-title>{{ $helper.tRoute($route.name) }}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-icon @click="$router.replace({ name: 'home' })">mdi-close</v-icon>
          </v-toolbar>
          <v-card-text>
            <v-form id="form" ref="form">
              <v-text-field
                v-model="form.email"
                id="email"
                :label="$t('Cols.users.email')"
                maxlength="35"
                readonly
                ref="email"
                :rules="[rules.required, rules.email]"
                validate-on-blur
              ></v-text-field>
              <v-text-field
                v-model="form.password"
                @click:append="showPassword = !showPassword"
                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                :hint="passwordHint"
                id="password"
                maxlength="20"
                :label="$t('Cols.users.password')"
                ref="password"
                :rules="[rules.required, rules.min]"
                :type="showPassword ? 'text' : 'password'"
                validate-on-blur
              ></v-text-field>
              <v-text-field
                v-model="form.password_confirmation"
                @click:append="showPassword = !showPassword"
                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                :hint="passwordHint"
                id="password_confirmation"
                maxlength="20"
                :label="$t('Cols.users.password_confirmation')"
                ref="password_confirmation"
                :rules="[rules.required, rules.min]"
                :type="showPassword ? 'text' : 'password'"
                validate-on-blur
              ></v-text-field>
              <div>
                <input type="hidden" name="token" id="token" v-model="form.token" />
              </div>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              @click="resetPassword"
              :color="actionColor"
              dark
              id="resetPassword"
              small
              >{{ $t("Auth.resetPassword") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-overlay :value="loading">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "ResetPassword",

  data() {
    return {
      actionColor: this.$settings.appColor,

      form: {
        email: "",
        password: "",
        password_confirmation: "",
        token: "",
      },

      loading: false,

      rules: {
        email: (v) => /.+@.+\..+/.test(v) || this.$t("Auth.validEmail"),
        min: (v) =>
          (v && v.length >= this.passwordLength) ||
          this.$t("Auth.minChars", [this.passwordLength]),
        required: (v) => !!v || this.$t("fieldRequired"),
      },

      showPassword: false,
    };
  },

  computed: {
    passwordLength() {
      return process.env.VUE_APP_APP_ENV == "local" ? 1 : 8;
    },

    passwordHint() {
      return this.$t("Auth.passwordHint", [this.passwordLength]);
    },
  },

  created() {
    this.form.email = this.$route.query.email;
    this.form.token = this.$route.params.token;
  },

  methods: {
    ...mapActions({
      authResetPassword: "auth/resetPassword",
      alertError: "app/alertError",
      alertSuccess: "app/alertSuccess",
      alertWarning: "app/alertWarning",
    }),

    error(e) {
      if (this.$helper.error(e) == "The given data was invalid.") {
        this.$helper.setFieldErrors(e, this);
        this.alertError(this.$t("invalidData"));
      } else {
        let message = this.$t("Auth.resetPasswordError") + this.$helper.error(e);
        this.alertError(message);
      }
    },

    resetPassword() {
      if (this.validForm()) {
        this.loading = true;
        this.authResetPassword(this.form)
          .then(() => {
            this.alertSuccess(this.$t("Auth.passwordReset"));
            this.$router.replace({
              name: "login",
              query: { email: this.form.email },
            });
          })
          .catch((e) => this.error(e))
          .finally(() => (this.loading = false));
      }
    },

    validForm() {
      if (this.$refs.form.validate()) return true;

      this.alertWarning(this.$t("invalidData"));
    },
  },
};
</script>
